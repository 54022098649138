<template>
  <div
    class="relative h-svh max-h-[760px] w-full md:aspect-[16/9] md:h-auto md:max-h-none"
  >
    <div
      class="paragraph-image-video-base h-full w-full"
      :class="{ 'has-overlay': overlay && !hideOverlay }"
    >
      <slot name="image"></slot>
    </div>
    <div
      v-show="!hideOverlay"
      class="mt-20 absolute left-0 top-0 z-10 flex h-full w-full items-center text-center text-white"
    >
      <div class="mx-auto w-full px-24 md:px-0 xxl:max-w-[700px]">
        <div
          v-if="overline || isEditing"
          v-blokkli-editable:field_image_overline
          class="mb-16 text-base uppercase tracking-[1.28px]"
        >
          {{ overline }}
        </div>
        <Text
          v-if="headline || isEditing"
          v-blokkli-editable:field_image_headline
          class="text-5xl uppercase md:text-7xl"
          :class="{
            'font-bold': isRebranding,
          }"
        >
          {{ headline }}
        </Text>
        <div v-if="link || linkAction" class="mt-40">
          <DrupalLink
            :to="link"
            class="link-button m-auto flex w-fit gap-16 pb-8 uppercase tracking-[0.8px] text-grey-light-02 underline-offset-[14px] hover:text-white focus:text-white"
            @click="linkAction"
          >
            <slot name="callToActionText"></slot>
          </DrupalLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  overline?: string
  headline?: string
  link?: string
  linkAction?: () => void
  overlay?: boolean
  isEditing?: boolean
  hideOverlay?: boolean
}>()

const isRebranding = await useFeatureFlag()
</script>

<style lang="postcss" scoped>
.paragraph-image-video-base {
  &.has-overlay {
    &:after {
      content: '';
      @apply absolute bottom-0 left-0 right-0 z-0 block h-full w-full bg-black opacity-[.15];
    }
  }

  picture {
    @apply h-full;
  }

  img,
  :deep(img),
  video,
  :deep(video) {
    @apply absolute left-0 top-0 h-full w-full object-cover;
  }
}

[data-blokkli-editable-field] {
  min-height: 1em;
  min-width: 5em;
}
</style>
